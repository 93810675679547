<template>
  <div class="">
    <div class="bb-container bb-container--config">
      <no-ssr>
        <portal-target name="alerts_configuration"></portal-target>
      </no-ssr>
    </div>

    <div class="g-user-sidebar">
      <div class="bb-container bb-container--config">
        <div class="g-user-sidebar__grid">
          <left-menu active="my-piggybank"></left-menu>
          <div class="g-user-sidebar__content">
            <h2 class="g-user-sidebar__head">{{ $t("settings.myPiggybank") }}</h2>

            <div class="g-user-sidebar__main g-user-sidebar__main--piggybank">
              <div class="config-data">
                <div class="config-data__form" v-if="userHasPiggyBankEnabled">

                <h3 class="header-3">{{ $t("settings.piggybankMyBankAccount") }}</h3>
                <div class="bb-form-fieldset">
                  <div class="bb-form-label bb-form-label--bankaccount" v-if="externalAccount">
                    {{externalAccount.description}}
                  </div>
                  <div class="bb-form-label bb-form-label--bankaccount" v-else>
                    {{ $t("settings.piggybankMyBankAccountNotInformed") }}
                  </div>
                </div>


                <h3 class="header-3">{{ $t("generic.settings2") }}</h3>

                <div class="bb-form-fieldset">
                  <div class="bb-form-label">
                      {{ $t("settings.piggybankComissionsTitle") }}
                  </div>
                  <RadioContext
                    :items="optionsComisiones.items"
                    @change="save()"
                    fieldName="commissions"
                    v-model="optionsComisiones.selectedOption"
                  ></RadioContext>
                </div>

                <div class="bb-form-fieldset">
                  <div class="bb-form-label">
                    {{ $t("settings.piggybankEnabledInTitle") }}
                  </div>
                  <RadioContext
                    :items="optionsAportaciones.items"
                    @change="save()"
                    fieldName="contributions"
                    v-model="optionsAportaciones.selectedOption"
                  ></RadioContext>
                </div>

                  <div class="bb-form-fieldset">
                    <div :class="['bb-form-label', { 'bb-form-label--disabled': thresholdAmountDisabled }]">
                      {{ $t("settings.piggybankActivationThresholdAmount") }}
                    </div>
                    <input
                      v-model.number="activationThresholdAmount"
                      :class="thresholdAmountDisabled ? 'bb-form--disabled': 'bb-form'"
                      type="number"
                      maxlength="256"
                      name="activationThresholdAmount"
                      id="activationThresholdAmount"
                      required=""
                      min="0"
                      :disabled="thresholdAmountDisabled"
                      @blur="save()"
                      @change="save()"
                      step=".01"
                    />
                  </div>

                  <div class="bb-form-fieldset">
                    <button
                      class="button button--primary button--block"
                      @click.prevent="save(true)"
                    >
                      {{ $t("generic.save") }}
                    </button>
                  </div>
                </div>

                <div v-if="!userHasPiggyBankEnabled">
                  <div class="bb-form-item">
                    <div class="bb-form-label">{{ $t("settings.piggybankDisabled") }}</div>
                  </div>
                  <button
                    class="button button--primary button--block"
                    @click.prevent="$router.push({ name: 'hucha-crear' })"
                  >
                    {{ $t("generic.enable") }}
                  </button>
                </div>
                <div v-if="userHasPiggyBankEnabled" class="g-user-sidebar__delete">
                  <!-- <hr class="separator-line" /> -->
                  <span
                    class="link-delete link-delete--bold link-delete--smaller cursor-pointer"
                    @click.prevent="openDialogDependingOnBalance"
                  >
                    <i class="uil uil-multiply"></i>
                    {{ $t("settings.piggybankDisable") }}
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <alert v-show="showAlertLocal" @retry="deletingPiggyBank()" />
      </div>
    </div>

    <modal-dialog
      :show="showWarningDialog"
      @close="closeWarningDialog()"
      target-class="modal__card--mini"
    >
      <div class="content-modal">
        <div class="content-modal__header content-modal__header--w90">
          <div class="content-modal__title">{{ $t("settings.piggybankDisablePendingTransactionsTitle") }}</div>
          <div class="content-modal__subtitle">
            {{ $t("settings.piggybankDisablePendingTransactionsDescription") }}
          </div>
        </div>
        <div class="content-modal__form">
          <div class="content-modal__actions">
            <bbButton
              label="Ir a Mi hucha"
              :variant="'button button--primary'"
              @click="$router.push({ name: 'hucha' })"
            >
            </bbButton>
          </div>
        </div>
      </div>
    </modal-dialog>

    <modal-dialog
      :show="showDeletePiggyBankDialog"
      @close="closeDeletePiggyBankDialog()"
      target-class="modal__card--mini"
    >
      <div class="content-modal">
        <div class="content-modal__header content-modal__header--w90">
          <div class="content-modal__title">
            {{ $t("settings.piggybankDisableConfirmation") }}
          </div>
          <div class="content-modal__subtitle">
            {{ $t("settings.piggybankDisableConfirmationDescription")}}
          </div>
        </div>
        <div class="content-modal__form">
          <div class="content-modal__actions">
            <bbButton
              :label="$t('generic.disable')"
              :variant="'button button--primary'"
              :loading="deletingPiggyBank"
              @click="deletePiggyBank()"
            >
            </bbButton>
          </div>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { VTooltip } from "floating-vue";
import "floating-vue/dist/style.css";

import { mapGetters } from "vuex";
import ConfigurationAlert from "./components/ConfigurationAlert";
import NoSSR from "vue-no-ssr";
import LeftMenu from "./components/LeftMenu";
import moment from "moment";
import ModalDialog from "../../components/ui/ModalDialog";
import bbButton from "../../components/buttons/bbButton.vue";
import RadioContext from "../../components/forms/RadioContext.vue";
import {
  FEES_SUPPORTED_BY,
  ENABLED_IN as CONTRIBUTIONS_ENABLED_IN,
} from "../../constants/contributions-configuration";

moment.locale("moment_es");

function isResponseError(error) {
  return (
    error &&
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 599 &&
    typeof error.response.data === "string"
  );
}

export default {
  name: "ConfigurationMyPiggyBankPage",

  components: {
    "no-ssr": NoSSR,
    alert: ConfigurationAlert,
    LeftMenu,
    ModalDialog,
    bbButton,
    RadioContext,
  },
  metaInfo() {
    return {
      title: this.$t("generic.settings") + " | HelloBB"
    };
  },
  data() {
    return {
      title: "",
      name: "",
      showDeletePiggyBankDialog: false,
      showWarningDialog: false,
      deletingPiggyBank: false,
      optionsComisiones: {
        selectedOption: FEES_SUPPORTED_BY.contributor,
        items: [
          {
            value: FEES_SUPPORTED_BY.contributor,
            title: this.$t("settings.piggybankComissionsOption1Title"),
            description:
              this.$t("settings.piggybankComissionsOption1Description"),
          },
          {
            selectedOption: true,
            value: FEES_SUPPORTED_BY.owner,
            title: this.$t("settings.piggybankComissionsOption2Title"),
            description:
              this.$t("settings.piggybankComissionsOption2Description"),
          },
        ],
      },
      optionsAportaciones: {
        selectedOption: CONTRIBUTIONS_ENABLED_IN.all,
        items: [
          {
            selectedOption: true,
            value: CONTRIBUTIONS_ENABLED_IN.all,
            title: this.$t("settings.piggybankEnabledInOption1Title"),
            description: "",
          },
          {
            value: CONTRIBUTIONS_ENABLED_IN.funds,
            title: this.$t("settings.piggybankEnabledInOption2Title"),
            description: "",
          },
        ],
      },
      activationThresholdAmount: 0,
    };
  },
  directives: {
    tooltip: VTooltip,
  },
  computed: {
    ...mapGetters({
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
      user: "user",
      balance: "balance",
      contributionsConfiguration: "contributionsConfiguration",
      contributionsConfigurationEnabledIn: "contributionsConfigurationEnabledIn",
      contributionsConfigurationFeesSupportedBy: "contributionsConfigurationFeesSupportedBy",
      contributionsConfigurationActivationThresholdAmount: "contributionsConfigurationActivationThresholdAmount",
      externalAccount: "externalAccount",
    }),
    userHasPiggyBankEnabled() {
      const { stripeAccountId } = this.user;

      return typeof stripeAccountId === "string" && stripeAccountId.trim() !== "";
    },
    showAlertLocal() {
      return this.showAlertKey === this.key && this.showAlert;
    },
    key() {
      return "configuration_page";
    },
    selectedCommission() {
      const value = this.optionsComisiones.selectedOption;
      const selectedItem = this.optionsComisiones.items.find((item) => item.value === value);
      return selectedItem || null;
    },
    selectedContribution() {
      const value = this.optionsAportaciones.selectedOption;
      const selectedItem = this.optionsAportaciones.items.find((item) => item.value === value);
      return selectedItem || null;
    },
    activationThresholdAmountDisplay() {
      return this.$utils.formatMoney(this.activationThresholdAmount);
    },
    thresholdAmountDisabled() {
      return this.selectedContribution.value !== CONTRIBUTIONS_ENABLED_IN.all;
    },
  },
  methods: {
    async openDialogDependingOnBalance() {
      const balance = await this.$store.dispatch("getBalance", this.user.stripeAccountId);

      if (balance.payoutAvailable + balance.payoutPending > 0) {
        return this.openWarningDialog();
      }

      return this.openDeletePiggyBankDialog();
    },

    async deletePiggyBank() {
      this.deletingPiggyBank = true;

      try {
        await this.$store.dispatch("deletePiggyBank", this.user.stripeAccountId);

        this.$store.commit("updateUserField", {
          field: "stripeAccountId",
          value: null,
        });

        this.$alert.success(this.$t("settings.piggybankDisableSuccess"), this.key);
      } catch (error) {
        console.log(error);

        if (isResponseError(error)) {
          // The raw response body is expected to be the error message.
          const errorMessage = error.response.data.trim();
          if (errorMessage) {
            this.$alert.error(errorMessage, this.key);

            return;
          }
        }

        // Fallback message.
        this.$alert.error(
          this.$t("generic.errorNoSave"),
          this.key
        );

        return;
      } finally {
        this.deletingPiggyBank = false;
        this.closeDeletePiggyBankDialog();
      }
    },

    openDeletePiggyBankDialog() {
      this.showDeletePiggyBankDialog = true;
    },
    closeDeletePiggyBankDialog() {
      this.showDeletePiggyBankDialog = false;
    },

    openWarningDialog() {
      this.showWarningDialog = true;
    },
    closeWarningDialog() {
      this.showWarningDialog = false;
    },

    async save(showSuccessAlert = false) {
      const enabledIn = this.selectedContribution.value;
      const feesSupportedBy = this.selectedCommission.value;
      const activationThresholdAmount = this.activationThresholdAmount;

      const contributionsConfiguration = {
        enabledIn,
        feesSupportedBy,
        activationThresholdAmount,
      };

      try {
        await this.$store.dispatch("updateContributionConfiguration", contributionsConfiguration);

        if (showSuccessAlert) {
          this.$alert.success(this.$t("generic.saveMessage"), this.key);
        }
      } catch (err) {
        //this.$alert.error("Algo ha ido mal. No hemos podido guardar los cambios.");
        this.$alert.error(this.$t("generic.errorNoSave"), this.key);

        throw err;
      }
    },
    async getExternalAccount() {
      const stripeAccountId = this.user?.stripeAccountId;
      if (stripeAccountId) {
        await this.$store.dispatch("getExternalAccount", {
          stripeAccountId,
        });
      }

      return this.externalAccount;
    },
  },
  async mounted() {
    this.optionsComisiones.selectedOption = this.contributionsConfigurationFeesSupportedBy;
    this.optionsAportaciones.selectedOption = this.contributionsConfigurationEnabledIn;
    this.activationThresholdAmount = this.contributionsConfigurationActivationThresholdAmount;

    if (this.userHasPiggyBankEnabled && !this.contributionsConfiguration) {
      const contributionsConfiguration = {
        enabledIn: CONTRIBUTIONS_ENABLED_IN.all,
        feesSupportedBy: FEES_SUPPORTED_BY.contributor,
        activationThresholdAmount: 0,
      };

      await this.$store.dispatch("updateContributionConfiguration", contributionsConfiguration);
    }

    await this.getExternalAccount();
  },
};
</script>
