<template>
  <div class="bb-radio-context">
    <label
      class="bb-radio-context__item"
      v-for="(item, index) in items"
      :key="index"
      :class="value == item.value ? 'bb-radio-context__item--value' : ''"
      @click="change(item.value)"
    >
      <input
        type="radio"
        :name="fieldName"
        v-bind:value="item.value"
        v-on:input="change(item.value)"
        v-on:change="change(item.value)"
        visibility="hidden"
      />

      <div
        class="bb-radio-context__ico"
        :class="value == item.value ? '' : 'bb-radio-context__ico--disabled'"
      >
        <i class="uil" :class="value == item.value ? 'uil-check' : 'uil-times'"></i>
      </div>

      <div class="bb-radio-context__content">
        <div class="bb-radio-context__title">{{ item.title }}</div>
        <div class="bb-radio-context__description" v-if="item.description">
          {{ item.description }}
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioContext",
  props: {
    fieldName: String,
    items: Array,
    value: String,
  },
  data: {
    // TODO: Remove this when possible.
    //
    // This is only needed because, when the `@click` is triggered in the
    // label, the events are called multiple times. The timeout ensures the
    // change callback is only executed once.
    //
    // And that `@click` is only necessary because sometimes when an option is
    // clicked, the selected value doesn't change.
    changeTimeout: null,
  },
  methods: {
    change(value) {
      clearTimeout(this.changeTimeout);

      this.changeTimeout = setTimeout(() => {
        this.$emit("input", value);
        this.$emit("change", value);
      }, 0);
    },
  },
};
</script>
